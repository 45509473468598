





















import Vue from "vue";
import store from "./store/shop";
import _ from "lodash";
import { Platform } from "./common/util/platform";
import { PlaySound } from "./common/util/playsound";
import * as SettingIndex from "./model/shop/shop/settingIndex";
import { AxiosResponse } from "axios";
import { Env } from "./common/util/env";
import commonStore from "./store/common";
import VueScrollTo from "vue-scrollto";
import mqtt from "mqtt";

import "./directives/cursor";
import "./directives/ripple";
import "./directives/click2top";
import { ulid } from "ulid";
import { ServedCode } from "./model/entity/orderSetting";

Vue.use(VueScrollTo);

const acceptCallApi = {
  table_list: {
    page: ["home", "table-list"],
    callApi: function () {
      store.dispatch("fetchTableSessions");
      store.commit("setPageChange", "tableList");
    },
  },
  table_session_order: {
    page: ["home", "table-list"],
    callApi: function () {
      store.dispatch("fetchTableSessions");
      store.dispatch("fetchNotificationTimeLimit");
      store.commit("setPageChange", "tableList");
    },
  },
  table_session_checkout: {
    page: [],
    callApi: function (context, data) {
      if (["home", "table-list"].includes(context.page)) {
        store.dispatch("fetchTableSessions");
        store.dispatch("fetchNotificationTimeLimit");
      } else if (["order_list", "order_list_serving"].includes(context.page)) {
        const served = (context.page === "order_list") ? ServedCode.SERVED_ORDER
          : (context.page === "order_list_serving") ? ServedCode.SERVED_READY
          : NaN;
        store.dispatch("fetchOrderList", { served });
      }
      store.dispatch("fetchShopMessage");
      store.commit("setPageChange", "tableList");
      store.commit("setPageChange", "check");
      store.commit("setPageChange", "order");
    },
  },
  customer_display: {
    page: ["check", "display"],
    callApi: function () {
      store.dispatch("fetchPayments");
    },
  },
  shop_printer: {
    page: [],
    callApi: function () {
      store.dispatch("fetchPrinterStatus");
    },
  },
  takeout: {
    page: ["takeout"],
    callApi: function () {
      store.dispatch("fetchCheckTakeout");
      store.dispatch("fetchTakeoutSessions");
      store.commit("setPageChange", "order");
    },
  },
  update_menu: {
    page: ["order", "category", "category_group", "cart"],
    callApi: function () {
      store.commit("setPageChange", "menu");
    },
  },
  update_setting_shop: {
    page: [],
    callApi: function (context) {
      context.fetchShopSetting();
    },
  },
};

export default Vue.component("Shop", {
  components: {
    SHHeader: () => import("./components/compositions/SHHeader.vue"),
    SHSideMenu: () => import("./components/compositions/SHSideMenu.vue"),
    SHNavMenu: () => import("./components/compositions/SHNavMenu.vue"),
  },
  data() {
    return {
      sideMenuDrawer: !Platform.isMobile,

      isAlreadyPooling: false,
      addClass: {},
      soundFiles: {
        order: "/assets/selforder.mp3",
        takeout_order: "/assets/takeout.mp3",
      },
      latestOrderCheck: {
        order: false,
        takeout: false,
      },
      connection: {
        host: store.state.shopIotLink, // teslint-disable-line
        clientId: ulid(),
        keepalive: 30,
      },
    };
  },
  mounted() {
    // load sound files
    PlaySound.load(this.soundFiles);
    commonStore.dispatch("fetchHoliday");
  },
  computed: {
    page() {
      if (this.$route.name) {
        return this.$route.name;
      }
      return "hall";
    },
    pageTitle() {
      const langKey = store.state.langKey;
      const staticTranslate = store.state.staticTranslates[langKey] || [];

      switch (this.page) {
        case "login":
          return "ログイン";
        case "home":
          return "HOME";
        case "loading":
          return "loading";
        case "soldout":
          return "SOLDOUT";
        case "soldout_category":
          return this.titleTable() + (store.state.selectedCategory.name || "");
        case "soldout_group":
          let soldout_group = _.find(
            store.state.categoryGroups,
            (categoryGroup) =>
              categoryGroup.id === this.$route.params.category_group_id
          );

          return this.titleTable() + soldout_group.name;
        case "takeout":
          return "TAKEOUT";
        case "order":
          return this.titleTable() + (store.state.selectedCategory.name || "");
        case "printer":
          return staticTranslate["hall-0022"];
        case "history":
          return this.titleTable() + staticTranslate["hall-0007"];
        case "category":
          return this.titleTable() + staticTranslate["hall-0086"];
        case "category_group":
          let category_group = _.find(
            store.state.categoryGroups,
            (categoryGroup) =>
              categoryGroup.id === this.$route.params.category_group_id
          );

          return this.titleTable() + category_group.name;
        case "check":
          return this.titleTable() + staticTranslate["hall-0182"];
        case "checkQuick":
          return this.titleTable();
        case "cart":
          return this.titleTable() + staticTranslate["hall-0190"];
        case "settled":
          return staticTranslate["hall-0010"];
        case "closing":
          return staticTranslate["hall-0011"];
        case "setting":
          return staticTranslate["hall-0137"];
        case "table-list":
          return staticTranslate["hall-0231"];
        case "setting-last_order":
          return staticTranslate["hall-0210"];
        case "display":
          return staticTranslate["hall-0259"];
        case "order_list":
        case "order_list_serving":
        case "order_list_completed":
        case "order_list_canceled":
          return staticTranslate["hall-0261"];
      }

      return "-------";
    },
    isShowHeader() {
      if (this.page === "login") {
        return false;
      }
      if (this.page === "loading") {
        return false;
      }
      if (this.page === "error") {
        return false;
      }
      if (this.page === "display") {
        return false;
      }
      if (store.state.shopSetting && !store.state.shopSetting.is_enable) {
        return false;
      }

      return true;
    },
    isShowNavMenu() {
      this.$route.name, store.state.shopSetting;
      if (store.state.shopSetting && !store.state.shopSetting.has_guide) {
        return false;
      }
      // HCO10
      const listPage = ["home", "table-list"];
      return this.$route.name ? listPage.includes(this.$route.name) : false;
    },
    containerClass() {
      if (store.state.shopSetting && !store.state.shopSetting.has_guide) {
        return { SelfType: true };
      }
      if (!this.isShowNavMenu) {
        return { SelfType: true };
      }

      return {};
    },

    mainClass() {
      this.addClass = {
        open: this.sideMenuDrawer,
      };
      switch (this.page) {
        case "home":
          this.addClass.TableSelect = true;
          break;
        case "table-list":
        case "home":
          this.addClass.TableSelect = true;
          break;
        case "soldout":
          this.addClass.Soldout = true;
          break;
        case "soldout_category":
        case "soldout_group":
          this.addClass.SoldoutCategory = true;
          break;
        case "takeout":
          this.addClass.Takeout = true;
          break;
        case "closing":
          this.addClass.Closing = true;
          break;
        case "printer":
          this.addClass.Receipt = true;
          break;
        case "settled":
          this.addClass.Settled = true;
          break;
        case "order":
        case "category":
        case "category_group":
          this.addClass.Order = true;
          break;
        case "history":
          (this.addClass.Order = true), (this.addClass.History = true);
          break;
        case "check":
          (this.addClass.Order = true), (this.addClass.Check = true);
          break;
        case "checkQuick":
          (this.addClass.Order = true), (this.addClass.Check = true);
          break;
        case "cart":
          (this.addClass.Order = true), (this.addClass.Cart = true);
          break;
        case "setting":
          this.addClass.Setting = true;
          break;
        case "display":
          this.addClass.Customerdisplay = true;
          break;
        case "order_list":
        case "order_list_serving":
        case "order_list_completed":
        case "order_list_canceled":
          this.addClass.OrderList = true;
          break;
      }

      return this.addClass;
    },
    shopSetting() {
      return store.state.shopSetting;
    },
    shopIotSetting() {
      return store.state.shopIotLink;
    },
    wsSignedUrl() {
      return store.state.wsUrl;
    }
  },
  created() {
    store.dispatch("init").catch((err) => {
      console.error(err);
    });
    commonStore.dispatch("setMode", "shop");
    this.createConnection();
  },
  watch: {
    pageTitle() {
      this.setTitle(this.pageTitle);
    },
    shopSetting: {
      deep: true,
      handler() {
        // do nothing
      },
    },
    "$route.params.shoppix"() {
      const prefix = this.$route.params.prefix;
      const shoppix = this.$route.params.shoppix;
      const token = this.$route.query.token;
      if (shoppix === undefined) {
        this.$router.push(`/error`);
        return;
      }

      store.commit("load", shoppix);

      // setting check：未ロード -> loading
      const setting = store.state.shopSetting;
      if (setting === null) {
        this.$router
          .push(`/${prefix}/${shoppix}/loading?token=${token}`)
          .catch(() => {});
      }
    },
    wsSignedUrl: function(newVal, oldVal) {
      console.log("Watch :" + newVal);
      if (!this.client.connected) {
        this.connection.host = newVal;
        this.createConnection();
      }
    }
  },
  methods: {
    titleTable() {
      const langKey = store.state.langKey;
      const staticTranslate = store.state.staticTranslates[langKey] || [];
      if (store.state.tableSession === null) {
        return "";
      }
      var tableTitle = "";
      var no;
      var uketsuke = staticTranslate["hall-0004"];
      // console.log('shopSetting', store.state.tableSession, store.state.shopSetting)
      if (
        store.state.shopSetting &&
        store.state.shopSetting.has_guide &&
        store.state.tableSession &&
        store.state.tableSession.table_seat &&
        store.state.tableSession.table_seat.name
      ) {
        tableTitle = store.state.tableSession.table_seat.name;
      } else if (store.state.tableSession.no !== null) {
        no = store.state.tableSession.no;
        tableTitle += `${uketsuke}${no}`;
      }
      return `<span class="Small">${tableTitle}</span>`;
    },
    // dom
    handleToggleSideMenu() {
      this.sideMenuDrawer = !this.sideMenuDrawer;
    },
    handleBack() {
      this.backScreen();
    },
    handleLogout() {
      this.logout();
    },

    // logic
    setTitle(title: string) {
      if (!title) {
        document.title = "Vivo";
        return;
      }
      document.title = `Vivo - ${title.replace(/(<([^>]+)>)/gi, "")}`;
    },
    fetchShopSetting() {
      const prefix = this.$route.params.prefix;
      const shoppix = this.$route.params.shoppix;
      store
        .dispatch("fetchShopSetting")
        .then((response: AxiosResponse<SettingIndex.response>) => {
          console.log(response);
          if (response.data.status !== "success") {
            throw new Error();
          }
          if (!response.data.data.is_enable) {
            this.$router
              .push(`/${prefix}/${shoppix}/invalid_account`)
              .catch(() => {});
          }

          return response.headers["vivo-api-token"];
        })
        .then((token: string) => {
          // re-store new token
          store.dispatch("setApiToken", token);
        });
    },
    backScreen() {
      const prefix = this.$route.params.prefix;
      const shoppix = this.$route.params.shoppix;

      const base = `/${prefix}/${shoppix}`;
      const tableSessionId = this.$route.params.table_session_id;

      switch (this.page) {
        case "home":
          // nothing
          break;
        case "soldout":
          this.$router.push(`${base}/home`);
          break;
        case "soldout_category":
          let soldout_group_parent = _.find(
            store.state.categoryGroups,
            (categoryGroup) =>
              categoryGroup.category_id === this.$route.params.category_id
          );

          if (soldout_group_parent && soldout_group_parent.parent_id) {
            this.$router.push(
              `${base}/soldout/category_group/${soldout_group_parent.parent_id}`
            );
          } else {
            this.$router.push(
              `${base}/soldout/`
            );
          }
          break;
        case "soldout_group":
          let soldout_group = _.find(
            store.state.categoryGroups,
            (categoryGroup) =>
              categoryGroup.id === this.$route.params.category_group_id
          );

          if (soldout_group.parent_id) {
            this.$router.push(
              `${base}/soldout/category_group/${soldout_group.parent_id}`
            );
          } else {
            this.$router.push(
              `${base}/soldout/`
            );
          }
          break;
        case "takeout":
          this.$router.push(`${base}/home`);
          break;
        case "closing":
          this.$router.push(`${base}/home`);
          break;
        case "printer":
          this.$router.push(`${base}/home`);
          break;
        case "settled":
          break;
        case "setting":
          this.$router.push(`${base}/home`);
          break;
        case "setting-last_order":
          this.$router.push(`${base}/hall_setting`);
          break;
        case "order":
          let category_group_parent = _.find(
            store.state.categoryGroups,
            (categoryGroup) =>
              categoryGroup.category_id === this.$route.params.category_id
          );

          if (category_group_parent && category_group_parent.parent_id) {
            this.$router.push(
              `${base}/table-session/order/${tableSessionId}/category_group/${category_group_parent.parent_id}`
            );
          } else {
            this.$router.push(
              `${base}/table-session/order/${tableSessionId}/categories/`
            );
          }
          break;
        case "history":
        case "category":
          this.$router.push(`${base}/home`);
          break;
        case "category_group":
          let category_group = _.find(
            store.state.categoryGroups,
            (categoryGroup) =>
              categoryGroup.id === this.$route.params.category_group_id
          );

          if (category_group.parent_id) {
            this.$router.push(
              `${base}/table-session/order/${tableSessionId}/category_group/${category_group.parent_id}`
            );
          } else {
            this.$router.push(
              `${base}/table-session/order/${tableSessionId}/categories/`
            );
          }

          break;
        case "checkQuick":
          this.$router.push(`${base}/table-session/cart/${tableSessionId}`);
          break;
        case "check":
          this.$router.push(`${base}/home`);
          break;
        case "cart":
          this.$router.push(
            `${base}/table-session/order/${tableSessionId}/categories`
          );
          // this.$router.push(`${base}/table-session/order/${tableSessionId}`);
          break;
        case "display":
          this.$router.push(`${base}/home`);
          break;
      }
    },
    logout() {
      store.dispatch("logout").then(() => {
        const shoppix = this.$route.params.shoppix;
        const logoutUrl = `${Env.HOST_ADMIN}/c/${shoppix}/logout`;

        location.href = logoutUrl;
      });
    },
    createConnection() {
      const { host, ...options } = this.connection;
      try {
        this.client && this.client.end();
        this.client = mqtt.connect(host, options);
      } catch (error) {
        console.log("mqtt.connect error", error);
      }
      this.client.on("connect", () => {
        console.log("Connection succeeded!");
        this.doSubscribe();
      });
      this.client.on("error", (error) => {
        console.log("Connection failed", error);
      });
      this.client.on("message", (topic, message) => {
        this.handleReciverMessage(topic, message);
      });

      this.client.on("reconnect", () => {
        console.log('reconnect');
        if (!this.client.connected) {
          setTimeout(() => {
            store.dispatch("getWsUrl");
          }, 5000) // auto reconnect after 5s
        }
      });
    },
    doSubscribe() {
      const { qos, topic } = {
        topic: 'shop/' + store.state.shopId,
        qos: 0,
      };

      this.client.subscribe(topic, { qos }, (error, res) => {
        if (error) {
          console.log("Subscribe to topics error", error);
          return;
        }
        console.log("Subscribe to topics res", res);
      });
    },
    handleReciverMessage(topic, message) {
      let payload = JSON.parse(message);
      if (
        acceptCallApi[payload.type] &&
        acceptCallApi[payload.type].page.includes(this.page)
      ) {
        acceptCallApi[payload.type].callApi(this, payload);
        store.commit("setPageChangeValue", payload.value)
      } else if (
        acceptCallApi[payload.type] &&
        acceptCallApi[payload.type].page.length === 0 // case alway call api
      ) {
        acceptCallApi[payload.type].callApi(this, payload);
        store.commit("setPageChangeValue", payload.value)
      }
    },
  },
});
