import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './Shop.vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from "@sentry/tracing";
import LogRocket from 'logrocket';

LogRocket.init('vceehl/vivo-5hkgt');

Vue.config.productionTip = false;

import './loadElementComponents';
import { Env } from './common/util/env';

Vue.use(VueRouter);

const routes = [
    {
        path: '/:prefix/:shoppix/',
        redirect: '/:prefix/:shoppix/home',
    },
    {
        path: '/:prefix/:shoppix/table-session/check/:table_session_id',
        name: 'check',
        component: () => import('./pages/shop/Check.vue'),
    },
    {
        path: '/:prefix/:shoppix/table-session/check-quick/:table_session_id',
        name: 'checkQuick',
        component: () => import('./pages/shop/CheckQuick.vue'),
    },
    {
        path: '/:prefix/:shoppix/table-session/history/:table_session_id',
        name: 'history',
        component: () => import('./pages/shop/History.vue'),
    },
    {
        path: '/:prefix/:shoppix/table-session/cart/:table_session_id',
        name: 'cart',
        component: () => import('./pages/shop/Cart.vue'),
    },
    {
        path: '/:prefix/:shoppix/table-session/order/:table_session_id/categories/:category_id',
        name: 'order',
        component: () => import('./pages/shop/Order.vue'),
    },
    {
        path: '/:prefix/:shoppix/table-session/order/:table_session_id/category_group/:category_group_id',
        name: 'category_group',
        component: () => import('./pages/shop/CategoryGroup.vue'),
    },
    {
        path: '/:prefix/:shoppix/table-session/order/:table_session_id/categories',
        name: 'category',
        component: () => import('./pages/shop/Category.vue'),
    },
    {
        path: '/:prefix/:shoppix/printer',
        name: 'printer',
        component: () => import('./pages/shop/Printer.vue'),
    },
    {
        path: '/:prefix/:shoppix/takeout',
        name: 'takeout',
        component: () => import('./pages/shop/Takeout.vue'),
    },
    {
        path: '/:prefix/:shoppix/home',
        name: 'home',
        component: () => import('./pages/shop/Index.vue'),
    },
    {
        path: '/:prefix/:shoppix/journals/closing',
        name: 'closing',
        component: () => import('./pages/shop/Closing.vue'),
    },
    {
        path: '/:prefix/:shoppix/settled',
        name: 'settled',
        component: () => import('./pages/shop/Settled.vue'),
    },
    {
        path: '/:prefix/:shoppix/hall_setting',
        name: 'setting',
        component: () => import('./pages/shop/Setting.vue'),
    },
    {
        path: '/:prefix/:shoppix/table-list',
        name: 'table-list',
        component: () => import('./pages/shop/TableList.vue'),
    },
    {
        path: '/:prefix/:shoppix/hall_setting/last_order_time',
        name: 'setting-last_order',
        component: () => import('./pages/shop/LastOrderTime.vue'),
    },
    {
        path: '/:prefix/:shoppix/soldout',
        name: 'soldout',
        component: () => import('./pages/shop/Soldout.vue'),
    },
    {
        path: '/:prefix/:shoppix/soldout/category_group/:category_group_id',
        name: 'soldout_group',
        component: () => import('./pages/shop/SoldoutGroup.vue'),
    },
    {
        path: '/:prefix/:shoppix/soldout/categories/:category_id',
        name: 'soldout_category',
        component: () => import('./pages/shop/SoldoutCategory.vue'),
    },
    {
        path: '/:prefix/:shoppix/display',
        name: 'display',
        component: () => import('./pages/shop/Display.vue'),
    },
    {
        path: '/:prefix/:shoppix/login',
        name: 'login',
        component: () => import('./pages/shop/Login.vue'),
    },
    {
        path: '/:prefix/:shoppix/loading',
        name: 'loading',
        component: () => import('./pages/shop/Loading.vue'),
    },
    {
        path: '/:prefix/:shoppix/invalid_account',
        component: () => import('./pages/shop/InvalidAccount.vue'),
    },
    {
      path: '/:prefix/:shoppix/orderList',
      name: 'order_list',
      component: () => import('./pages/shop/OrderList.vue'),
    },
    {
      path: '/:prefix/:shoppix/orderList/serving',
      name: 'order_list_serving',
      component: () => import('./pages/shop/OrderListServing.vue'),
    },
    {
      path: '/:prefix/:shoppix/orderList/completed',
      name: 'order_list_completed',
      component: () => import('./pages/shop/OrderListCompleted.vue'),
    },
  {
      path: '/:prefix/:shoppix/orderList/canceled',
      name: 'order_list_canceled',
      component: () => import('./pages/shop/OrderListCanceled.vue'),
    },
    {
        path: '*',
        redirect: '/error',
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/shop/Error.vue'),
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
    base: Env.ROUTER_BASE_SHOP,
});

Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DNS,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracingOptions: {
        trackComponents: true,
    },
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});

new Vue({
    router,
    render: h => h(App),
}).$mount('#app');
